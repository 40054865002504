export const homePageUrl = "/";
export const aboutPageUrl = "/about";
export const whyBrassPageUrl = "/why-brass";
export const retailPageUrl = "/retail";
export const internetCompaniesPageUrl = "/internet-companies";
export const capitalPageUrl = "/capital";
export const industyPageUrl = "/industry";
export const customersPageUrl = "/customers";
export const pressPageUrl = "/press";
export const helpPageUrl = "/help";
export const careersPageUrl = "/careers";
export const blogPageUrl = "/blog";
export const guidesPageUrl = "/guides";
export const ebookPageUrl = "/ebook";
export const launchpadPageUrl = "/launchpad";
export const launchpadHelpPageUrl = "/launchpad-help";
// export const payrollPageUrl = "/payroll";
export const cardsPageUrl = "/cards";

// LEGAL
export const termsOfServicePageUrl = "/legal/terms-of-service";
export const privacyPolicyPageUrl = "/legal/privacy-policy";
export const brassCapitalTermsPageUrl = "/legal/brass-capital";
export const cardsTermsAndConditionsPageUrl = "/legal/cards";
