import React from "react";
import { Button, ButtonVariant } from "brass-ui-kit";
import { APP_SIGNUP_URL } from "data/config";
import cx from "classnames";
import OpenAccountButton from "../OpenAccountButton/OpenAccountButton";

import * as styles from "./GetStarted.module.scss";

interface GetStartedProps {
  desc?: string;
  title?: string;
  theme?: "dark" | "light";
  buttonText?: string;
  buttonLink?: string;
  hasForm?: boolean;
  children?: React.ReactNode;
}

const GetStarted: React.FC<GetStartedProps> = ({
  desc,
  theme,
  title,
  children,
  buttonText,
  buttonLink,
  hasForm,
}) => {
  return (
    <section
      className={cx(styles.GetStarted, {
        [styles.GetStarted_theme_dark]: theme === "dark",
      })}
    >
      <div className={styles.GetStarted_container}>
        <header className={styles.GetStarted_header}>
          <h2 className={styles.GetStarted_header_title}>{title}</h2>
          <p
            className={styles.GetStarted_header_desc}
            dangerouslySetInnerHTML={{ __html: desc }}
          ></p>
        </header>

        {children}

        {hasForm && (
          <form className={styles.GetStarted_action}>
            <input
              id="email"
              type="email"
              name="email"
              aria-label="Email Address"
              placeholder="Email address"
            />
            <Button variant={ButtonVariant.Primary}>
              {!!buttonText ? buttonText : "Open an account"}
            </Button>
          </form>
        )}

        {!hasForm && (
          <div className={styles.GetStarted_action}>
            <OpenAccountButton link={buttonLink} label={buttonText} />
          </div>
        )}
      </div>
    </section>
  );
};

GetStarted.defaultProps = {
  theme: "dark",
  title: "Get started with ease for free",
  desc: `
  Get all the best financial products for your business growth for
  free. <br /> Open the last current account you will ever need for
  your business
  `,
  buttonLink: APP_SIGNUP_URL,
  buttonText: "Get started",
};

export default GetStarted;
